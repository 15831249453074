<template>
  <div>
    <b-row>
      <b-col md="3">
        <b-form-group
          label="Date"
          invalid-feedback="Date is required."
          ref="date"
        >
          <flat-pickr
            ref="date"
            :config="config"
            v-model="dateSelected"
            class="form-control"
            placeholder="Select Date"
            @on-change="loadData()"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row align-h="center" v-if="summLoading" class="mb-3 mt-1">
      <b-spinner
        style="width: 3rem; height: 3rem"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>
    <b-row class="mt-1" v-else>
      <b-col xl="2" md="4">
        <b-card body-class="pl-1">
          <b-media no-body>
            <b-media-aside class="mr-50">
              <b-avatar size="44" variant="light-success">
                <feather-icon size="24" icon="UserCheckIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="">
              <h4 class="font-weight-bolder mb-0">Present</h4>
              <b-card-text class="mb-0">
                {{ summaryObj.present }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <b-col xl="2" md="4">
        <b-card body-class="pl-1">
          <b-media no-body>
            <b-media-aside class="mr-50">
              <b-avatar size="44" variant="light-danger">
                <feather-icon size="24" icon="UserXIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="">
              <h4 class="font-weight-bolder mb-0">Absent</h4>
              <b-card-text class="mb-0">
                {{ summaryObj.absent }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <b-col xl="2" md="4">
        <b-card body-class="pl-1">
          <b-media no-body>
            <b-media-aside class="mr-50">
              <b-avatar size="44" variant="light-info">
                <feather-icon size="24" icon="PlusCircleIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="">
              <h4 class="font-weight-bolder mb-0">Leave</h4>
              <b-card-text class="mb-0">
                {{ summaryObj.leave }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <b-col xl="2" md="4">
        <b-card body-class="pl-1">
          <b-media no-body>
            <b-media-aside class="mr-50">
              <b-avatar size="44" variant="light-warning">
                <feather-icon size="24" icon="ClockIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="">
              <h4 class="font-weight-bolder mb-0">Late</h4>
              <b-card-text class="mb-0">
                {{ summaryObj.late }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <b-col xl="2" md="4">
        <b-card body-class="pl-1">
          <b-media no-body>
            <b-media-aside class="mr-50">
              <b-avatar size="44" variant="light-primary">
                <feather-icon size="24" icon="CloudIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="">
              <h4 class="font-weight-bolder mb-0">Holidays</h4>
              <b-card-text class="mb-0">
                {{ summaryObj.holidays }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <b-col xl="2" md="4">
        <b-card body-class="pl-1">
          <b-media no-body>
            <b-media-aside class="mr-50">
              <b-avatar size="44" variant="light-danger">
                <feather-icon size="24" icon="InfoIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="">
              <h4 class="font-weight-bolder mb-0">Suspended</h4>
              <b-card-text class="mb-0">
                {{ summaryObj.suspended }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <h3 class="">Present</h3>
        <hr />
        <b-tabs lazy pills>
          <b-tab
            v-for="(item, ind) in progressData"
            :key="ind"
            :title="item.department"
          >
            <b-card>
              <div
                class="d-flex justify-content-center mt-3 mb-3"
                v-if="dataLoading"
              >
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  variant="primary"
                  label="Spinning"
                  type="grow"
                ></b-spinner>
              </div>
              <template v-else v-for="data in item.data">
                <h5 class="d-flex flex-wrap">
                  {{ data.class }}
                  <span class="ml-auto text-success">{{ data.value }}%</span>
                </h5>
                <b-progress v-model="data.value" max="100" class="mb-1" />
              </template>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-col>
      <b-col lg="6">
        <h3 class="">Absent Since</h3>
        <hr />
        <b-tabs lazy pills>
          <b-tab
            v-for="(item, ind) in absentData"
            :key="ind"
            :title="item.department"
          >
            <b-card no-body>
              <b-table
                responsive
                :items="item.data"
                :fields="fields"
                class=""
                show-empty
                :busy="dataLoading"
              >
                <template #table-busy>
                  <div class="d-flex justify-content-center mt-2 mb-2">
                    <b-spinner
                      style="width: 3rem; height: 3rem"
                      variant="primary"
                      label="Spinning"
                      type="grow"
                    ></b-spinner>
                  </div>
                </template>
                <template #cell(day2)="data">
                  {{ data.value ? data.value : "-" }}
                </template>
                <template #cell(day3)="data">
                  {{ data.value ? data.value : "-" }}
                </template>
                <template #cell(day4)="data">
                  {{ data.value ? data.value : "-" }}
                </template>
                <template #cell(above4)="data">
                  {{ data.value ? data.value : "-" }}
                </template>
              </b-table>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-col>
      <b-col lg="12">
        <h3 class="">Defaulters</h3>
        <hr />
        <b-tabs lazy pills>
          <b-tab
            v-for="(item, ind) in feeData"
            :key="ind"
            :title="item.department"
          >
            <b-card no-body>
              <b-table
                responsive
                :items="item.data"
                :fields="fieldsFee"
                class=""
                show-empty
                :busy="dataLoading"
              >
                <template #table-busy>
                  <div class="d-flex justify-content-center mt-2 mb-2">
                    <b-spinner
                      style="width: 3rem; height: 3rem"
                      variant="primary"
                      label="Spinning"
                      type="grow"
                    ></b-spinner>
                  </div>
                </template>
                <template #cell(month1)="data">
                  {{ data.value ? data.value : "-" }}
                </template>
                <template #cell(month2)="data">
                  {{ data.value ? data.value : "-" }}
                </template>
                <template #cell(month3)="data">
                  {{ data.value ? data.value : "-" }}
                </template>
                <template #cell(above3)="data">
                  {{ data.value ? data.value : "-" }}
                </template>
                <template #cell(total)="data">
                  <b-badge variant="light-primary">
                    {{ data.item.total }}
                  </b-badge>
                </template>
              </b-table>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BAvatar,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BMediaBody,
  BLink,
  BBadge,
  BPagination,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BTabs,
  BTab,
  BProgress,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BAvatar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BMediaBody,
    BLink,
    BBadge,
    BPagination,
    VBTooltip,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BCardTitle,
    BCardText,
    BSidebar,
    VBToggle,
    vSelect,
    flatPickr,
    BTabs,
    BTab,
    BProgress,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      summLoading: false,
      summaryObj: {
        present: 0,
        absent: 0,
        leave: 0,
        late: 0,
        holidays: 0,
        suspended: 0,
      },
      dateSelected: new Date().toJSON().split("T")[0],
      dataLoading: false,
      progressData: [
        {
          department: "Pre-primary",
          data: [
            { class: "Play Group (A)", value: 20 },
            { class: "Play Group (B)", value: 60 },
          ],
        },
        {
          department: "Primary",
          data: [
            { class: "Nursery (A)", value: 0 },
            { class: "Nursery (B)", value: 15 },
          ],
        },
      ],
      absentData: [
        {
          department: "Pre-primary",
          data: [
            {
              class: "Play Group (A)",
              day2: "",
              day3: "2",
              day4: "",
              above4: "",
            },
            {
              class: "Play Group (B)",
              day2: "1",
              day3: "",
              day4: "",
              above4: "",
            },
          ],
        },
        {
          department: "Primary",
          data: [
            { class: "Nursery (A)", day2: "", day3: "", day4: "", above4: "1" },
            { class: "Nursery (B)", day2: "", day3: "", day4: "3", above4: "" },
          ],
        },
      ],
      feeData: [
        {
          department: "Pre-primary",
          data: [
            {
              class: "Play Group (A)",
              month1: 2500,
              month2: 0,
              month3: 0,
              above3: 15000,
              total: 17500,
            },
            {
              class: "Play Group (B)",
              month1: 0,
              month2: 0,
              month3: 5000,
              above3: 0,
              total: 5000,
            },
          ],
        },
        {
          department: "Primary",
          data: [
            {
              class: "Nursery (A)",
              month1: 3600,
              month2: 4000,
              month3: 0,
              above3: 0,
              total: 7600,
            },
            {
              class: "Nursery (B)",
              month1: 0,
              month2: 0,
              month3: 2000,
              above3: 19000,
              total: 21000,
            },
          ],
        },
      ],
      fields: [
        { label: "class", key: "class" },
        { label: "2 Days", key: "day2" },
        { label: "3 Days", key: "day3" },
        { label: "4 Days", key: "day4" },
        { label: "Above 4 Days", key: "above4" },
      ],
      fieldsFee: [
        { label: "class", key: "class" },
        { label: "1 Month", key: "month1" },
        { label: "2 Month", key: "month2" },
        { label: "3 Month", key: "month3" },
        { label: "Above 3 months", key: "above3" },
        { label: "total", key: "total" },
      ],
    };
  },
  created() {
    this.loadSummary();
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async loadData() {
      // console.log(this.dateSelected);
      if (this.dateSelected) {
        // this.dataLoading = true;
        this.loadSummary();
        // var obj = {
        //   url:
        //     this.$store.state.domain +
        //     "Studend/LoadDashboard?db=" +
        //     this.$store.state.userData.db +
        //     "&cID=" +
        //     this.$store.state.userData.cId +
        //     "&dt=" +
        //     this.dateSelected,
        //   token: this.$store.state.userData.token,
        // };
        // let res = await this.get(obj);
        // console.log(res);
        // this.dataLoading = false;
      }
    },

    async loadSummary() {
      this.summLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Staff/LoadAttendanceByDate?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&dt=" +
          this.dateSelected +
          "&role=student",
        token: this.$store.state.userData.token,
        dashboard: true,
      };
      let res = await this.get(obj);
      this.summaryObj = res.summary;
      // console.log(this.summaryObj);
      this.summLoading = false;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style></style>
